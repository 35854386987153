import ImgButton from "./img-button"

import React from "react"
import styled from "@emotion/styled"

export default ({title="", description="", time="", appleUrl="", googleUrl="", amazonUrl=""}) => {
  const Card = styled.div`
    background-color: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-width: 596px;
    padding: 30px;
    width:100%;

    h1 {
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 20px;
    }
  `;

  const Buttons = styled.div`
    display: flex;

    > * {
      margin-right: 5%;
      max-width:25%;
    }
  `;

  return(
    <Card>
      <div>
        <h1 className="text-left">{title}</h1>
        <p><span className="emphasis" dangerouslySetInnerHTML={{__html: description}}></span> {time}</p>
      </div>
      <Buttons>
        {appleUrl && <ImgButton img="apple-itunes" url={appleUrl} maxWidth="150px" width="40%" />}
        {amazonUrl && <ImgButton img="amazon-music-buy" url={amazonUrl} maxWidth="195px" width="52%" />}
      </Buttons>
    </Card>
  )
}
