import { Col, Container, Row } from "react-bootstrap"
import React from "react"
import styled from "@emotion/styled"

export default ({cols=2, xs=10, md=6, children}) => {

  let rows = []

  for (var i=0 ; i<children.length ; i+=cols) {
    rows.push(
      <Row className="justify-content-center" key={`grid-row-${i}`}>
        {[...Array(cols).keys()].map((colNum) => {
          return <Col className="my-3"  key={`grid-col-${i}-${colNum}`} xs={xs} md={md}>{children[i+colNum]}</Col>
        })}
      </Row>
    )
  }

  const Grid = styled(Container)`

  `;

  return(
    <Grid className="mx-auto my-0 p-0">
      {rows}
    </Grid>
  )
}
