import Img from "gatsby-image"

import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

export default (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile {
          edges {
            node {
              name
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );

  const edge = data.allFile.edges.find(edge => edge.node.name === props.src);
  const image = edge ? edge.node.childImageSharp.fluid : "" ;

  const imageProps = {
    alt: props.alt || "",
    className: props.className,
    style: {
      height: props.height || "100%",
      maxWidth: props.maxWidth || "100%",
      objectFit: props.objectFit || "cover",
      width: props.width || "100%"
    }
  }

  const img = image ? <Img fluid={image} {...imageProps} /> : <img src={props.src} {...imageProps} />
  const inner =
    <>
      {img}
      {props.children}
    </>

  const passedProps = {
    "className": props.containerClassName,
    "href": props.url,
    "style": {
      // display: "flex",
      // justifyContent: "center",
      height: props.containerHeight || "100%",
      margin: props.margin || "0 auto",
      maxWidth: props.containerMaxWidth || "inherit",
      width: props.containerWidth || "100%",
      zIndex: props.zIndex
    }
  }

  return(
    <>
      {
        props.url ? <a {...passedProps}><div className="center-content">{inner}</div></a> : <div {...passedProps}>{inner}</div>
      }
    </>
  )

}
