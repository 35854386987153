import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Col, Row } from "react-bootstrap";
import CardGrid from "../components/card-grid";
import Content from "../components/content";
import PurchaseCard from "../components/purchase-card";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content className="py-5 text-left" maxWidth="1241px" mdxType="Content">
      <CardGrid cols={2} md={5} xs={11} mdxType="CardGrid">
        <PurchaseCard title="Ode to Joy Op. 125" description="Symphony No. 9, Ludwig Van Beethoven" time="1:56" appleUrl="https://music.apple.com/us/album/symphony-no-9-op-125-ode-to-joy/1615839409?i=1615839424" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_HQ9MLDExLIv2brglQyJvjJ04N&trackAsin=B09WBWMLSF" mdxType="PurchaseCard" />
        <PurchaseCard title="Inventio I" description="Klavrierbüchlien, Johann Sebastian Bach" time="1:56" appleUrl="https://music.apple.com/us/album/kalmus-piano-series-two-and-three-part-inventions/1615839409?i=1615839470" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_tmAf9rMjeFcz8kPDrlOjq5VR5&trackAsin=B09WBJ2FXR" mdxType="PurchaseCard" />
        <PurchaseCard title="Waltz in Db Major" description="Op.64 No.1, Frédéric Chopin" time="2:41" appleUrl="https://music.apple.com/us/album/waltz-in-d-flat-major-op-64-no-1/1615839409?i=1615839474" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_eyglPEGQMscg81ur40QDHUzBX&trackAsin=B09WCH3Y6G" mdxType="PurchaseCard" />
        <PurchaseCard title="Nocturne" description="Op. 54 No. 4, Edvard Grieg" time="6:05" appleUrl="https://music.apple.com/us/album/lyric-pieces-op-54-iv-nocturne/1615839409?i=1615839478" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_E3nmVGREo6oK3IBCXWFKgPNFw&trackAsin=B09WC3SZWN" mdxType="PurchaseCard" />
        <PurchaseCard title="Clair de Lune" description="“Suite Bergamasque”, Claude Debussy" time="3:14" appleUrl="https://music.apple.com/us/album/suite-bergamasque-l-75-iii-clair-de-lune/1615839409?i=1615839481" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_C5sgMXPkBUKRL4nH6FuNvHPMo&trackAsin=B09WBVK9MN" mdxType="PurchaseCard" />
        <PurchaseCard title="Sonatina in C" description="Op. 36 No.1, Muzio Clementi" time="1:12" appleUrl="https://music.apple.com/us/album/sonatina-no-1-in-f-major-op-36-ii-andante/1615839409?i=1615839484" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_n8aG8aHATj7M49PquAYD2GZWu&trackAsin=B09WBVLMFS" mdxType="PurchaseCard" />
        <PurchaseCard title="Tales From the Vienna Woods" description="Johann Strauss II" time="3:17" appleUrl="https://music.apple.com/us/album/tales-from-the-vienna-woods-op-325-in-f-major-piano-solo/1615839409?i=1615839486" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_I5zGFWFSbpFRqS48cUxb5hjJY&trackAsin=B09WBYSDBM" mdxType="PurchaseCard" />
        <PurchaseCard title="Waltz in Ab Major" description="Op. 39 No.15, Johannes Brahms" time="1:18" appleUrl="https://music.apple.com/us/album/16-waltzes-op-39-no-15-in-a-flat-major-piano-solo/1615839409?i=1615839487" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_y0O8ZpC27ULDKNRPUgEWw0wiq&trackAsin=B09WC8ZLDL" mdxType="PurchaseCard" />
        <PurchaseCard title="Piano Concerto No. 21" description="Symphony No. 9, Ludwig Van Beethoven" time="1:56" appleUrl="https://music.apple.com/us/album/piano-concerto-no-21-in-c-major-k-467-ii-andante/1615839409?i=1615839488" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_kGhXRUEpxeKElUp1FrWxVGZmP&trackAsin=B09WC684KT" mdxType="PurchaseCard" />
        <PurchaseCard title="The Merry Farmer" description="Robert Schumann" time="0:54" appleUrl="https://music.apple.com/us/album/album-for-the-young-op-68-10-the-merry-farmer-in-f/1615839409?i=1615839489" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_d71cXrPXxfrxiNrdxaB4Fi3CI&trackAsin=B09WC8NGYT" mdxType="PurchaseCard" />
        <PurchaseCard title="Italian Folk Song" description="Piotr Illich Tchaikovsky" time="0:58" appleUrl="https://music.apple.com/us/album/italian-folk-songin-d-major-gp-20-piano-solo/1615839409?i=1615839490" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_N7EYRRvho2PVf7dnWWg5Y2PpX&trackAsin=B09WBT1LW7" mdxType="PurchaseCard" />
        <PurchaseCard title="Valse" description="Franz Schubert" time="1:09" appleUrl="https://music.apple.com/us/album/three-waltzes-d-365-ii-trauerwalzer-in-a-flat-major/1615839409?i=1615839491" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_yRpbVECpY47Wm59nWoKz2BA2U&trackAsin=B09WC2GDGZ" mdxType="PurchaseCard" />
        <PurchaseCard title="He Shall Feed His Flock" description="From the “Messiah”, George Frideric Handel" time="2:15" appleUrl="https://music.apple.com/us/album/messiah-hwv-56-20-he-shall-feed-his-flock-piano-solo/1615839409?i=1615839492" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_cajIwevuUPDd2sCDQiWDBrUJH&trackAsin=B09WCBLVZF" mdxType="PurchaseCard" />
        <PurchaseCard title="Meditation" description="From “Thaïs” in D Major, Jules Massenet" time="2:27" appleUrl="https://music.apple.com/us/album/thais-act-ii-meditation-piano-solo-in-g-major/1615839409?i=1615839493" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_QwcXt6qXsMxrwFbKYGxVSy1q7&trackAsin=B09WBYN4XH" mdxType="PurchaseCard" />
        <PurchaseCard title="Contredanse" description="Joseph Haydn" time="1:13" appleUrl="https://music.apple.com/us/album/contredanse-in-f-major-piano-solo/1615839409?i=1615839494" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_Vv4a23GQNEZUDgQN2MBT2NHAA&trackAsin=B09WC94DSB" mdxType="PurchaseCard" />
        <PurchaseCard title="Für Elise" description="Ludwig Van Beethoven" time="3:33" appleUrl="https://music.apple.com/us/album/f%C3%BCr-elise-woo-59/1615839409?i=1615839495" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_RrKMsZQKDiArM5cDp5Fktg42H&trackAsin=B09WBVLMFR" mdxType="PurchaseCard" />
        <PurchaseCard title="Sketch" description="Op.47, No.3, Stephen Heller" time="1:19" appleUrl="https://music.apple.com/us/album/sketch-op-47-no-3-in-a-minor/1615839409?i=1615839496" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_hwG29zr5pji5zshrciYjyBMaF&trackAsin=B09WBKX427" mdxType="PurchaseCard" />
        <PurchaseCard title="To a Wild Rose" description="from “Woodland Sketches,” Op. 51, Edward Mac Dowell" time="1:54" appleUrl="https://music.apple.com/us/album/10-woodland-sketches-op-51-i-to-a-wild-rose/1615839409?i=1615839497" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_Xvh0hSrXbTydXq719Vay4cUdG&trackAsin=B09WBX37F5" mdxType="PurchaseCard" />
        <PurchaseCard title="Amazing Grace" description="Traditional American Melody" time="3:29" appleUrl="https://music.apple.com/us/album/amazing-grace-in-d-flat-major-to-a-major-piano-solo/1615839409?i=1615839498" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_z4HETQUZM7ucc5Fr5t5UpQHEG&trackAsin=B09WBZDY64" mdxType="PurchaseCard" />
        <PurchaseCard title="Canon in D" description="in D. Johann Pachelbel" time="3:04" appleUrl="https://music.apple.com/us/album/canon-in-d-major-piano-solo/1615839409?i=1615839499" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_TpDAipt0BO8y7PNDzJi9LBHr7&trackAsin=B09WC3XL2L" mdxType="PurchaseCard" />
        <PurchaseCard title="Spinning Song" description="Op. 14, No. 4, Albert Ellmenreich" time="1:55" appleUrl="https://music.apple.com/us/album/spinning-song-in-f-major-op-14-i-allegretto/1615839409?i=1615839500" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_MGoGECBoDkXjUtxJgOS2Q3ZcU&trackAsin=B09WC44CS1" mdxType="PurchaseCard" />
        <PurchaseCard title="I Know That My Redemer Liveth" description="From the “Messiah”, George Frideric Handel" time="2:01" appleUrl="https://music.apple.com/us/album/messiah-hwv-56-45-i-know-that-my-redeemer-liveth-piano-solo/1615839409?i=1615839501" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_Eeb9FM4thdKYLDrW7Qd4PfywB&trackAsin=B09WC8H4CZ" mdxType="PurchaseCard" />
        <PurchaseCard title="Moonlight Sonata" description="Ludwig Van Beethoven" time="2:49" appleUrl="https://music.apple.com/us/album/theme-from-moonlight-sonata-no-2-in-d-minor-op-27-i/1615839409?i=1615839502" amazonUrl="https://amazon.com/music/player/albums/B09WC2ZZSN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_IKq06e5t4i0mkPbzZ1r9RNmn5&trackAsin=B09WC3DX8B" mdxType="PurchaseCard" />
        <PurchaseCard title="Purchase CDs" description="To purchase a physical CD of 23 songs call, 504.834.8255 or fill out the <a href='/contact'>contact</a> form." mdxType="PurchaseCard" />
      </CardGrid>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      