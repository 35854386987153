import BackgroundBlock from "../components/background-block"
import Layout from "../components/layout"

import PurchaseMDX from "../content/purchase"

import React from "react"

export default () => {
  return (
    <Layout title="Purchase">
      <BackgroundBlock src="purchase-bg" repeat="repeat" size="contain">
        <PurchaseMDX />
      </BackgroundBlock>
    </Layout>
  )
}
